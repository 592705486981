import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import useRequest from '../../hooks/useRequest';
import { Severty, ShowToast } from '../../helper/toast';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const labels = [
  "Jan",
  "Fab",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const labels1 = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24"
];

const labels2 = [
  "Week 1",
  "Week 2",
  "Week 3",
  "Week 4",
  "Week 5"
];


export const options = {
  type: "line",
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Sales Report",
    },
  },
};

const barChartData = {
  labels: ["Jan", "Fab", "Mar", "Apr", "May", "Jun", "Jul"],
  datasets: [
    {
      label: "Sales",
      data: [1, 0, 30, 50, 20, 40, 10, 0],
      backgroundColor: "rgba(52, 122, 226, 0.16)",
      borderColor: "red",
      borderWidth: 1,
      pointStyle: "circle",
      pointRadius: 10,
      pointHoverRadius: 15,
    },
  ],
};


const LineChart = ({ period = 'yesterday', borderColor = 'rgb(75, 192, 192)' }) => {

  const [data, setData] = useState();
  const { request } = useRequest();

  const fetchData = () => {
    request({
      url: `admin/dashboard/graph-data?period=${period}`,
      method: "GET",
      onSuccess: ({ result, status, total, message }) => {
        if (status) {
          let label = period == "monthly" ? labels : period == "weekly" ? labels2 : labels1
          let datasets = label.map((ff, index) => {
            const find = result.find(
              (item) => parseInt(item.interval) - 1 == index,
            );
            if (find) {
              return find.total_amount;
            }
            return 0;
          });

          const barChartData = {
            labels: period == "monthly" ? labels : period == "weekly" ? labels2 : labels1,
            datasets: [
              {
                label: "Sales",
                data: datasets,
                backgroundColor: "rgba(52, 122, 226, 0.16)",
                borderColor: "red",
                borderWidth: 1,
                pointStyle: "circle",
                pointRadius: 10,
                pointHoverRadius: 15,
              },
            ],
          };

          setData(barChartData);
        }
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [period]);


  return (
    <div>
      <Line
        borderColor="#1EB564"
        options={options}
        data={data ?? barChartData}
      />
    </div>
  )
}

export default LineChart