export const PRICE = "OMR";

export const currencies = [
  { label: "ALL", value: "ALL" },
  { label: "USD", value: "USD" },
  { label: "AED", value: "AED" },
  { label: "HKD", value: "HKD" },
  { label: "SGD", value: "SGD" },
];

export const bannerType = [
  { id: "normal", value: "Normal" },
  { id: "category", value: "Category" },
  // {id : 'offer', value : "Offer"}
];

export const cities = [
  "Adam",
  "Al Ashkharah",
  "Al Buraimi",
  "Al Hamra",
  "Al Jazer",
  "Al Madina A'Zarqa",
  "As Sib",
  "Al Suwaiq",
  "Bahla",
  "Barka",
  "Bidbid",
  "Bidiya",
  "Duqm",
  "Haima",
  "Ibra",
  "Ibri",
  "Izki",
  "Jabrin",
  "Jalan Bani Bu Hassan",
  "Khasab",
  "Mahooth",
  "Manah",
  "Masirah",
  "Matrah",
  "Mudhaybi",
  "Mudhaireb",
  "Muscat",
  "Nizwa",
  "Quriyat",
  "Raysut",
  "Rustaq",
  "Ruwi",
  "Saham",
  "Shinas",
  "Saiq",
  "Salalah",
  "Samail",
  "Sohar",
  "Sur",
  "Tan`am",
  "Thumrait",
  "Yanqul",
];
