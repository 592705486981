import { UndoOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Input,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";

import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import moment from "moment";
const { RangePicker } = DatePicker;

export const OrderStatus = {
  SUCCESS: "success",
  FAILED: "failed",
};

export const Status = [
  {
    label: "SUCCESS",
    value: "success",
  },
  {
    label: "FAILED",
    value: "failed",
  },
];

function Index() {
  const { setPageHeading } = useContext(AppStateContext);
  const heading = "Tramsaction Manager";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const api = {
    status: apiPath.transaction,
    list: apiPath.transaction,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [orderStatus, setOrderStatus] = useState();
  const [viewModal, showViewModal] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const deposits = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
      render: (_, { transaction_id }) => (
        <span color="white">#{transaction_id || "-"}</span>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (_, { user_id }) =>
        user_id ? (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {user_id?.name && <span className="cap">{user_id.name}</span>}
            {user_id?.country_code && user_id?.mobile_number && (
              <span className="cap" style={{ fontSize: "14px", color: "gray" }}>
                (
                {user_id.country_code?.includes("+")
                  ? user_id.country_code
                  : "+" + user_id.country_code}
                ) {user_id.mobile_number}
              </span>
            )}
            {user_id?.email && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {user_id.email}
              </span>
            )}
          </div>
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        let nameA = a.user_id?.name;
        let nameB = b.user_id?.name;
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, { wallet_id }) => (
        <span className="cap">
          {wallet_id?.currency}
        </span>
      ),
    },
    {
      title: "Transaction Amount",
      dataIndex: "transaction_amount",
      key: "transaction_amount",
      render: (_, { transaction_amount }) => {
        return <span>{transaction_amount?.toFixed(2) || "-"}</span>;
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      render: (_, { type }) => {
        return <span className="text-uppercase">{type || "-"}</span>;
      },
    },
    {
      title: "Order ID",
      dataIndex: "type",
      key: "type",
      render: (_, { order_id }) => {
        return order_id?.order_id ? (
          <span className="text-uppercase">#{order_id?.order_id}</span>
        ) : (
          <span> - </span>
        );
      },
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (_, { created_at }) => {
        return (
          <span>
            {created_at
              ? moment(created_at).format("ll")
              : moment(Date.now()).format("ll")}
          </span>
        );
      },
    },
  ];

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    request({
      url:
        api.list +
        `?page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}${
          path ? `&status=${path}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, statusText, message }) => {
        setLoading(false);
        if (statusText === "true") {
          setList(data?.transactions || []);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: data?.total,
          }));
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, startDate, endDate, orderStatus]);

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };

  return (
    <>
      <SectionWrapper
        cardHeading={"Transaction List"}
        extra={
          <>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder="Status"
                value={orderStatus}
                filterOption={false}
                options={Status.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                onChange={(value) => setOrderStatus(value)}
              />
            </div>
            <div className="w-100 text-head_right_cont">
              {/* <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} /> */}
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={"Search by Customer Name or Order ID"}
                  value={searchText}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <Button
                onClick={() => {
                  setSearchText("");
                  setOrderStatus();
                }}
                type="primary"
                icon={<UndoOutlined />}
              >
                Reset
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={deposits}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showQuickJumper: true,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>
    </>
  );
}

export default Index;
