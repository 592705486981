let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://tradingbackend.quicklyom.com/api/";
} else {
  URL = "https://tradingbackend.quicklyom.com/api/";
  // URL = "http://localhost:8500/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "admin/dashboard",
  importStockData: "admin/dashboard/import-stock-price",
  getStockData: "common/dashboard/get-stock-price",

  order: "admin/order",
  viewOrder: "admin/order/view",
  statusOrder: "admin/order/status",
  transaction: "admin/order/transaction",

  getOpenPositions: "admin/dashboard/open-position",

  // Auth API
  logout: "admin/auth/logout",
  login: "admin/auth/login",

  profile: "admin/auth/get-profile",
  updateProfile: "admin/auth/update-profile",
  changePassword: "admin/auth/change-password",
  updateAppSetting: "admin/auth/update-app-setting",

  forgotPassword: "admin/auth/forgot-password",
  verifyOTP: "admin/auth/verify-otp",
  resetPassword: "admin/auth/reset-password",

  // Markup APIs
  markupInfo: "admin/markup/info",
  applyMarkup: "admin/markup/apply",
  resetMarkup: "admin/markup/reset",

  // Customer APIs
  listCustomer: "admin/customer",
  addEditCustomer: "admin/customer",
  viewCustomer: "admin/customer/view",
  deleteCustomer: "admin/customer/delete",
  statusCustomer: "admin/customer/status",
  importCustomer: "admin/customer/import-file",

  orderList: "admin/customer/order",
  transactionList: "admin/customer/transaction",
  
  // Wallet APIs
  wallet: "admin/customer/wallet",
  addMoney: "admin/customer/add-money",
  removeMoney: "admin/customer/remove-money",
  clearPosition: "admin/customer/clear-position",
  resetPosition: "admin/customer/reset-position",
  
  banner: "admin/banner",
  adminCommon: "admin/common",

  // Content APIs
  content: "admin/content",
  notification: "admin/notification",
  notificationRead: "admin/notification/read-all",
  rating: "admin/rating",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/add-edit",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  common: {
    notification: "common/notify",
    content: "common/content",
  },

};

export default apiPath;
