export const BlockRestaurantReasons = [
  "Quality and Safety Issues",
  "Chronic Order Delays",
  "Misrepresentation",
  "Violation of Platform Policies",
  "Illegal Activities",
];

export const DeleteRestaurantReasons = [
  "Violation of Platform Policies",
  "Closure of the Vendor",
  "Operational Issues",
  "Quality and Safety Concerns",
  "Breach of Agreement",
];

export const BlockCollectorReasons = [
  "Violation of Collection Policies",
  "Fraudulent Activity",
  "Harassment or Unethical Behavior",
  "Breach of Security Protocols",
  "Non-Compliance with Platform Guidelines",
];

export const BlockCustomerReasons = [
  "Violation of Platform Policies",
  "Harassment or Inappropriate Behavior",
  "Chronic Order Issues",
  "Non-Payment or Payment Issues",
];

export const DeleteCustomerReasons = [
  "Violation of Platform Policies",
  "Harassment or Inappropriate Behavior",
  "Chronic Order Issues",
  "Non-Payment or Payment Issues",
];

export const BlockDriverReasons = [
  "Consistent Order Delays",
  "Poor Customer Service",
  "Reckless Driving",
  "Fraudulent Activity",
  "Violation of Platform Policies",
];

export const DeleteSubadmin = [
  "Violation of Company Policies",
  "Unauthorized Access or Misuse of Privileges",
  "Breach of Security Protocols",
  "Termination of Employment or Role",
  "Change in Organizational Structure or Roles",
];
