import React, { useEffect, useState } from "react";
import { Card, InputNumber, Switch, Button, Checkbox } from "antd";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import { useAppContext } from "../../context/AppContext";

// const currencies = [
//   "USD",
//   "EUR",
//   "GBP",
//   "CHF",
//   "AUD",
//   "JPY",
//   "MYR",
//   "SGD",
//   "HKD",
//   "CAD",
//   "TWD",
//   "KRW",
//   "CNY",
//   "PHP",
//   "NZD",
//   "ZAR",
//   "VND",
//   "INR",
//   "IDR",
//   "SAR",
//   "AED",
//   "RUB",
//   "SCO",
//   "QAR",
//   "OMR",
//   "BHD",
//   "KWD",
// ];

const currencies = ["USD", "AED", "HKD", "SGD"];

const markupInitialization = () => {
  return currencies.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: {
        type: false,
        buy_fee: 0,
        sell_fee: 0,
        currency: cur,
        common_fee: 0,
        is_common: false,
      },
    }),
    {}
  );
};

const Index = () => {
  const [markupValues, setMarkupValues] = useState(markupInitialization());
  const [loading, setLoading] = useState(false);
  const [resets, setResets] = useState({
    "USD": true,
    "AED": true,
    "HKD": true,
    "SGD": true,
  });
  const { setPageHeading } = useAppContext();
  const { request } = useRequest();

  const handleMarkupChange = (currency, key, value) => {
    console.log(currency, key, value);
    setMarkupValues((prev) => {
      return {
        ...prev,
        [currency]: { ...prev[currency], [key]: value },
      };
    });
  };

  const applyMarkup = (currency) => {
    console.log(markupValues[currency]);
    request({
      url: apiPath.applyMarkup,
      method: "POST",
      data: { ...markupValues[currency] },
      onSuccess: (data) => {
        ShowToast(data?.message, Severty.SUCCESS);
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  const resetMarkup = (currency) => {
    setResets((prev)=>{
      return {
        ...prev,
        [currency] : !prev[currency],
      }
    })
  };

  const markupInfo = () => {
    setLoading(true);
    request({
      url: apiPath.markupInfo,
      method: "GET",
      onSuccess: (data) => {
        if (data.statusText == "true") {
          const markups = data?.data?.reduce((acc, mrkp) => {
            return { ...acc, [mrkp.currency]: { ...mrkp } };
          }, {});
          console.log(markups);
          setMarkupValues((prev) => {
            return { ...prev, ...markups };
          });
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    markupInfo();
    setPageHeading("Currency Markup");
  }, []);

  useEffect(() => {
    console.log(markupValues, "--------------> markupValues");
  }, [markupValues]);

  return (
    <Card className="mt-3 custom-card">
      <div className="admin-panel">
        <h2>Currency Spread Markup</h2>
        {currencies.map((currency) => {
          const markup = markupValues[currency];
          return (
            <div key={currency} className="markup-controls">
              <span className="currency-label">{currency}</span>
              <div className="switch-container">
                <span className="number-icon">🔢</span>
                <Switch
                  checked={markup.type}
                  onChange={(checked) =>
                    handleMarkupChange(currency, "type", checked)
                  }
                />
                <span className="percent-symbol">%</span>
              </div>

              <Checkbox
                checked={markup.is_common}
                onChange={(e) =>
                  handleMarkupChange(currency, "is_common", e.target.checked)
                }
              >
                Common
              </Checkbox>

              {!markup.is_common ? (
                <>
                  <InputNumber
                    disabled={resets[currency]}
                    value={markup.buy_fee}
                    min={0}
                    onChange={(value) =>
                      handleMarkupChange(currency, "buy_fee", value)
                    }
                    placeholder="Buy"
                    step={0.0001}
                    className="input-buy-sell"
                  />
                  <InputNumber
                    disabled={resets[currency]}
                    value={markup.sell_fee}
                    min={0}
                    onChange={(value) =>
                      handleMarkupChange(currency, "sell_fee", value)
                    }
                    placeholder="Sell"
                    step={0.0001}
                    className="input-buy-sell"
                  />
                </>
              ) : (
                <InputNumber
                  disabled={resets[currency]}
                  value={markup.common_fee}
                  min={0}
                  onChange={(value) =>
                    handleMarkupChange(currency, "common_fee", value)
                  }
                  placeholder="Common markup"
                  step={0.0001}
                  className="input-buy-sell"
                />
              )}

              <Button
                disabled={resets[currency]}
                type="primary"
                className="apply-button-btn"
                onClick={() => applyMarkup(currency)}
              >
                Apply Markup
              </Button>
              <Button
                disabled={!resets[currency]}
                type="danger"
                className="reset-button-btn"
                onClick={() => resetMarkup(currency)}
              >
                Reset Markup
              </Button>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default Index;
