import {
  Button,
  Card,
  Switch,
  Table,
  Tooltip,
  Form,
  Input,
  Modal,
  Row,
  Col,
  DatePicker,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Plus from "../../../assets/images/plus.svg";
import DeleteModal from "../../../components/DeleteModal";
import ViewLogs from "../../../components/ViewLogs";
import apiPath from "../../../constants/apiPath";
import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../../constants/reasons";
import { AppStateContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import AddFrom from "./AddFrom";
import { useNavigate } from "react-router";
import ViewWallet from "./ViewWallet";

const { RangePicker } = DatePicker;

function Index() {
  const heading = lang("Customers") + " " + lang("Manager");
  const { setPageHeading } = useContext(AppStateContext);
  const Search = Input.Search;
  const sectionName = lang("Customer");
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");

  const api = {
    status: apiPath.statusCustomer,
    addEdit: apiPath.addEditCustomer,
    list: apiPath.listCustomer,
    delete: apiPath.listCustomer,
    addMoney: apiPath.addMoney,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [showWallet, setShowWallet] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const navigate = useNavigate();
  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1, pageSize: 10 });
  };

  const onDelete = (id) => {
    request({
      url: api.delete + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.list + "/status/" + id,
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  // const onChangePassword = (values) => {
  //   const { password } = values;
  //   const payload = {};
  //   if (!password)
  //     return ShowToast("Please enter password ", Severty.ERROR);
  //   setLoading(true);
  //   payload.password = password;
  //   payload.id = selected._id;
  //   request({
  //     url: "/admin/auth/reset-password-admin",
  //     method: "POST",
  //     data: payload,
  //     onSuccess: (data) => {
  //       setLoading(false);
  //       if (data.status) {
  //         ShowToast(data.message, Severty.SUCCESS);
  //         setResetPassword(false);
  //         form.resetFields();
  //         // logout();
  //       } else {
  //         ShowToast(data.message, Severty.ERROR);
  //       }
  //     },
  //     onError: (error) => {
  //       ShowToast(error.response.data.message, Severty.ERROR);
  //       setLoading(false);
  //     },
  //   });
  // }

  const columns = [
    // {
    //   title: "S. No",
    //   dataIndex: "index",
    //   key: "index",
    //   render: (value, item, index) =>
    //     pagination.current === 1
    //       ? index + 1
    //       : (pagination.current - 1) * pagination.pageSize + (index + 1),
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      // sorter: (a, b) => a.name?.localeCompare(b.name),
      render: (_, { name }) => {
        return name ? name : "-";
      },
    },
    {
      title: "Gold Stock (KG)",
      dataIndex: "gold_stock",
      key: "gold_stock",
      width: 200,
      // sorter: (a, b) => a?.gold_stock?.localeCompare(b?.gold_stock),
      render: (_, { gold_stock }) => {
        return gold_stock ? gold_stock : "0";
      },
    },
    {
      title: "Phone Number",
      // sorter: (a, b) => a.mobile_number?.localeCompare(b.mobile_number),
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code
            ? country_code?.includes("+")
              ? country_code + "-"
              : `+${country_code}-`
            : "+968") + (mobile_number ? mobile_number : "")
        );
      },
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      // sorter: (a, b) => a.email?.localeCompare(b.email),
      render: (_, { email }) => {
        return email ? (
          <span style={{ textTransform: "lowercase" }}>{email}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Status",
      key: "is_active",
      dataIndex: "is_active",
      className: "driver-status",
      render: (_, { _id, is_active }) => {
        return (
          <Switch
            onChange={() => {
              handleChangeStatus(_id);
            }}
            checked={is_active}
          />
        );
      },
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
    },
    {
      title: "Added On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip title={"Edit"} color={"purple"} key={"edit"}>
              <Button
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record);
                  setVisible(true);
                }}
              >
                <i style={{ color: "fff" }} class="far fa-edit"></i>
              </Button>
            </Tooltip>

            <Tooltip title={"Wallet"} color={"purple"} key={"Wallet"}>
              <Button
                className="edit-cls primary_btn"
                onClick={() => {
                  setSelected(record);
                  navigate(`/customer/wallets/${record?._id}`);
                }}
              >
                <i
                  style={{ fontSize: "medium", color: "fff" }}
                  class="fa fa-wallet"
                ></i>
              </Button>
            </Tooltip>

            <Tooltip title={"Delete"} color={"purple"} key={"Delete"}>
              <Button
                className="btnStyle deleteDangerbtn"
                onClick={() => {
                  setSelected(record);
                  setShowDelete(true);  
                }}
              >
                <i
                  style={{ fontSize: "small", color: "fff" }}
                  class="fa fa-light fa-trash"
                ></i>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, startDate, endDate]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}${path ? `&status=1` : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data?.data);
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: data.total,
        }));
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };

  return (
    <>
      <div className="tabled productCaralogue">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={"Customers List"}
              extra={
                <>
                  <div className="button_group justify-content-end w-100">
                    <Search
                      allowClear
                      className="search-input"
                      size="large"
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder="Search By Name, email, number"
                      title="Search By Name, email, number"
                    />
                    <RangePicker
                      disabledDate={(current) => current.isAfter(Date.now())}
                      onChange={handleChangeDate}
                    />
                    {/* <Button
                      onClick={() => {
                        setEndDate();
                        setStartDate();
                        setSearchText('');
                      }
                      }
                      type="primary"
                      icon={<UndoOutlined />}
                    >
                      {lang("Reset")}
                    </Button> */}
                    <Button
                      className="primary_btn btnStyle"
                      onClick={(e) => {
                        setVisible(true);
                        setSearchText("");
                      }}
                    >
                      <span className="add-Ic">
                        <img src={Plus} />
                      </span>
                      {"Add"} {sectionName}
                    </Button>
                  </div>
                </>
              }
            >
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                  }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <AddFrom
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {/* {
        resetPassword && (
          <Modal
            open={resetPassword}
            width={800}
            // title={`${data ? "Edit Delivery Agent " : "Add Delivery Agent"}`}
            okText='Reset Password'
            cancelText={lang("Cancel")}
            onCancel={() => setResetPassword(false)}
            okButtonProps={{
              form: "create",
              htmlType: "submit",
              loading: loading,
            }}
            className="tab_modal"
          >

            <Form id="create" form={form} onFinish={onChangePassword} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={12} sm={12}>
                  <Form.Item
                    className="mb-0"
                    label={`New Password`}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Password Is Required",
                      },

                    ]}

                  >
                    <Input.Password autoComplete="off" placeholder={`Password`} />
                  </Form.Item>
                </Col>

                <Col span={12} sm={12}>
                  <Form.Item
                    className="mb-0"
                    label={`Confirm Password`}
                    name="confirm_password"
                    rules={[
                      { required: true, message: "Please input the confirm password!" },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords that you entered do not match!")
                          );
                        },
                      }),
                    ]}
                    normalize={(value) => value.trimStart()}
                  >
                    <Input.Password
                      autoComplete="off"
                      placeholder={`Confirm Password`}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )
      } */}

      {showDelete && (
        <DeleteModal
          title={lang("Delete User")}
          subtitle={lang(`Are you sure you want to delete this user?`)}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
          reasons={DeleteCustomerReasons}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${
            selected?.is_active ? lang(`Block`) : lang(`UnBlock`)
          } ${lang("User")}`}
          subtitle={`${lang("Are you sure you want to")} ${
            selected?.is_active ? lang(`block`) : lang(`unblock`)
          } ${lang("this user?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
          reasons={BlockCustomerReasons}
        />
      )}
    </>
  );
}

export default Index;
