import { UndoOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Input,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import DeleteModal from "../../components/DeleteModal";
import moment from "moment";
import { currencies } from "../../constants/conts";
const { RangePicker } = DatePicker;

export const OrderStatus = {
  SUCCESS: "success",
  FAILED: "failed",
  PENDING: "pending",
};

export const Status = [
  {
    label: "SUCCESS",
    value: "success",
  },
  {
    label: "PENDING",
    value: "pending",
  },
];

function Index() {
  const { setPageHeading } = useContext(AppStateContext);
  const heading = "Order Manager";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const api = {
    status: apiPath.order,
    list: apiPath.order,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [orderStatus, setOrderStatus] = useState();
  const [viewModal, showViewModal] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [extraKeys, setExtraKeys] = useState();
  const [currency, setCurrency] = useState("ALL");
  const [isClear, setIsClear] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const columns = [
    {
      title: "Order id",
      dataIndex: "index",
      key: "index",
      render: (_, { order_id }) =>
        order_id ? <span className="cap">#{order_id}</span> : "-",
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (_, { user_id }) =>
        user_id ? (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {user_id?.name && <span className="cap">{user_id.name}</span>}
            {user_id?.country_code && user_id?.mobile_number && (
              <span className="cap" style={{ fontSize: "14px", color: "gray" }}>
                (
                {user_id.country_code?.includes("+")
                  ? user_id.country_code
                  : "+" + user_id.country_code}
                ) {user_id.mobile_number}
              </span>
            )}
            {user_id?.email && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {user_id.email}
              </span>
            )}
          </div>
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        let nameA = a.user_id?.name;
        let nameB = b.user_id?.name;
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Quantity",
      key: "order_quantity",
      dataIndex: "order_quantity",
      render: (_, { quantity }) => {
        return <span className="cap">{quantity ?? 0} KG</span>;
      },
    },
    {
      title: "Quality",
      key: "order_quality",
      dataIndex: "order_quality",
      render: (_, { quality }) => {
        return <span className="cap">{quality}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, { currency }) => <span className="cap">{currency}</span>,
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => (
        <span className="cap">{amount?.toFixed(2) ?? 0.0}</span>
      ),
      sorter: (a, b) => {
        let nameA = a.amount;
        let nameB = b.amount;
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Order Status",
      className: "status-bedge",
      key: "created_at",
      render: (_, { status }) => {
        let color;
        if (status === OrderStatus.SUCCESS) {
          color = "green";
        } else if (status === OrderStatus.PENDING) {
          color = "blue";
        } else if (status === OrderStatus.FAILED) {
          color = "red";
        }

        return status ? (
          <Tag color={color}>{<span className="cap">{status}</span>}</Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Order Time",
      key: "created_time",
      render: (_, { created_at }) => {
        return created_at ? (
          <span className="text-uppercase">
            {moment(created_at).format("HH:mm a")}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Order Date",
      key: "created_date",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
      sorter: (a, b) => a.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            {!record?.clear_position ? (
              <Tooltip title={""} color={"purple"} key={""}>
                <Button
                  className="edit-cls btnStyle primary_btn"
                  onClick={() => {
                    setSelected(record);
                    setIsClear(true);
                  }}
                >
                  Clear
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title={""} color={"purple"} key={""}>
                <Button
                  className="delete-cls btnStyle primary_btn"
                  onClick={() => {
                    setSelected(record);
                    setIsReset(true);
                  }}
                >
                  Reset
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const fetchData = (pagination, filters) => {
    request({
      url:
        api.list +
        `?currency=${currency}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}${
          path ? `&status=${path}` : ""
        }&status=${orderStatus ? orderStatus : ""}`,
      method: "GET",
      onSuccess: ({ data, statusText, message }) => {
        setLoading(false);
        if (statusText === "true") {
          setList(data?.orders || []);
          setExtraKeys(data?.extraKeys);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: data?.total,
          }));
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const onClearPosition = (id) => {
    request({
      url: apiPath.clearPosition + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onResetPosition = (id) => {
    request({
      url: apiPath.resetPosition + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, startDate, endDate, orderStatus, currency]);

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };

  return (
    <>
      <SectionWrapper
        extra={
          <>
            <div>
              <h6
                style={{
                  color: "#636161",
                  marginInline: 10,
                  paddingInline: 10,
                  fontSize: "20px",
                  borderBlockEnd: "none",
                  borderBlockStart: "none",
                }}
              >
                Total Open Position: {extraKeys?.openPosition?.toFixed(2) || 0}{" "}
                (Kg)
              </h6>
              {extraKeys?.avgAmount && (<h6
                style={{
                  color: "#636161",
                  marginInline: 10,
                  paddingInline: 10,
                  fontSize: "20px",
                  borderBlockEnd: "none",
                  borderBlockStart: "none",
                }}
              >
                Avg Open Position: {extraKeys?.avgAmount ? extraKeys?.avgAmount == "NaN" ? 0 : extraKeys?.avgAmount : 0}{" "}
                ({currency})
              </h6>)}
            </div>

            <div className="w-100 text-head_right_cont">
              {/* <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} /> */}
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={"Search by Customer Name or Order ID"}
                  value={searchText}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <Button
                onClick={() => {
                  setSearchText("");
                  setOrderStatus();
                  setCurrency("ALL");
                }}
                type="primary"
                icon={<UndoOutlined />}
              >
                Reset
              </Button>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder="Status"
                  value={orderStatus}
                  filterOption={false}
                  options={Status.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  onChange={(value) => setOrderStatus(value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder="Status"
                  value={currency}
                  filterOption={false}
                  options={currencies.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  onChange={(value) => setCurrency(value)}
                />
              </div>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showQuickJumper: true,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {isClear && (
        <DeleteModal
          reasons={[]}
          title={"Clear Position"}
          subtitle={`Are you sure you want to Clear this position?`}
          show={isClear}
          hide={() => {
            setIsClear(false);
            setSelected();
          }}
          onOk={() => onClearPosition(selected?._id)}
        />
      )}
      {isReset && (
        <DeleteModal
          reasons={[]}
          title={"Clear Position"}
          subtitle={`Are you sure you want to Reset this position?`}
          show={isReset}
          hide={() => {
            setIsReset(false);
            setSelected();
          }}
          onOk={() => onResetPosition(selected?._id)}
        />
      )}
    </>
  );
}

export default Index;
